import NavigationBar from "./NavigationBar";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Something from "../pages/Something";
import Home from "../pages/Home";
import About from "../pages/About";
import Services from "../pages/Services";
import PlansAndPricing from "../pages/PlansAndPricing";
import Contact from "../pages/Contact";
import Footer from './Footer';
import "../css/maincontent.css";

function MainContent() {
  return (
    <div className="main-content-container">
      <Router>
        <NavigationBar />
        
        <div className="row">
          {/* <div className="page-content col-md-9 col-sm-12"> */}
          <div className="page-content-col-sm-12">
            <Switch>
              <Route path="/Something">
                <Something />
              </Route>

              <Route path="/Home">
                <Home></Home>
              </Route>

              <Route path="/About">
                <About></About>
              </Route>

              <Route path="/Services">
                <Services></Services>
              </Route>

              <Route path="/PlansAndPricing">
                <PlansAndPricing></PlansAndPricing>
              </Route>

              <Route path="/Contact">
                <Contact></Contact>
              </Route>
            </Switch>
              
          </div>
              
        </div>
          
      </Router>
      
      {/* <Footer/> */}
    </div>
  );
}

export default MainContent;
