import './ClientVideoText'
import ClientVideoText from './ClientVideoText';
//import classes from './BackgroundVideo.module.css';
// import './client.css';
import '../../css/client-video.css';


const ClientVideo = (props) => {
    const videoSource = "https://video.wixstatic.com/video/11062b_bcd6bf1267b1478f877210e2fa200397/720p/mp4/file.mp4"

    return (
        <section className='section-video'>
           <div className='div-client-video'>
                    <video
                        autoPlay="autoplay"
                        loop="loop"
                        muted
                        className='video-style'>
                        <source src={videoSource} type="video/mp4" />
                    </video>
                </div>
            <ClientVideoText />
        </section>
    )
}


export default ClientVideo;
