import React from "react";
import PageContent from "../components/PageContent";

function Something(props) {
  return (
    <div>
      <p>lorem500</p>
      <PageContent pageID="1"></PageContent>
    </div>
  );
}

export default Something;
