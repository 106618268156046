import '../css/maincontent.css';
//Dont Use this Class yet... its meant a CMS
function PageContent (props) {
    const pageContents = [
        {
          pageID: "1",
          pageTitle: "something",
          pageContentVerbiage: "some verbiage",
          pageURL: "someURL",
          homeparagraph: "dfjhkajhgoijdkjfkjdgkj",
        },
        {
            pageID: "2",
            pageTitle: "some title",
            pageContentVerbiage: "some verbiage",
            pageURL: "someURL",
          },
    ];

    return (

        <div className="page-content col-md-9 col-sm-12">
            <h1 className="page-title">Page Title</h1>
            <div className="page-entry">
                Page Verbiage
            </div>
            <div>
                <p pageid={pageContents[0].pageID}>this is it</p>
                <p pageid={pageContents[1].pageID}>this is one</p>
            </div>
            
        </div>
   //Wathiq: page title, page ID, page content, and URL
    );
}


export default PageContent;