import MainContent from "./components/MainContent";
import "./css/App.css";
import "./css/navbar.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <div className="App">
        <MainContent/>
    </div>
  );
}

export default App;