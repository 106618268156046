import React from "react";
import ContactPageForm from '../components/ContactPage/ContactPageForm';

function Contact(params) {
  return (
    
    <div>
      <ContactPageForm/>
      {/* <h1>This is Contact</h1>
      <p>lorem500Contact</p> */}
    </div>
    
  );
}

export default Contact;
