import React from "react";
import "../css/about.css";
import "../css/animations.css";

const AboutInfo = () => {
  return (
    <div className="containerForTextAndImage">
      <div className="containerTextBoxPosition textContainerAnimation">
        <h1 className="headingAnimation">About Bitslogical</h1>
        <p className="paragraphAnimation">Here at Bitslogical, our mission goes beyond providing technology solutions. We help our clients achieve the highest level of operational excellence so they can focus on what matters - their core business. Through our comprehensive range of services, we ensure our clients stay ahead in the ever-changing technology landscape and maximize their overall success.</p>
      </div>
       <div className="containerOverflow imageAnimation">
        <div className="imgPosition imgBgPosition">
        </div>
      </div> 
    </div>
  );
};

class About extends React.Component {
  render() {
    return (
      <section>
        <AboutInfo />
      </section>
    );
  }
}

export default About;
