import { text } from '@fortawesome/fontawesome-svg-core';
import './client.css';



function ClientVideoText () {

    return (
        <div class="video-text">
            <h2 className='client-video-text-h2'>
                <span className='.client-video-text-styles-one'>
                    <span className='.client-video-text-styles'> An Impressive Portfolio of Client</span>
                </span>
            </h2>
        </div>
    )
}

export default ClientVideoText;