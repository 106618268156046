import React from "react";
import "../css/plansAndPricing.css";

const cardArtOneTextTop = "Cyber Security";
const cardArtOneTextBottomFirewall = "Manage Firewall Services";
const cardArtOneTextBottomIncident = "Incident Response Services";
const cardArtOneTextBottomMDR = "Managed Detection and Response (MDR) Service";
const cardArtOneTextBottomCPS = "Check Point Support Service";
const cardArtOneTextBottomPrice = "$150 Per/Hr.";

const cardArtTwoTextTop = "Infrastructure Management";
const cardArtTwoTextBottomADS = "Architecture Design Services";
const cardArtTwoTextBottomAWS = "AWS Platform Service";
const cardArtTwoTextBottomCDS = "Cloud Health & Design Service";
const cardArtTwoTextBottomADA = "Azure Adoption Accelerator service";
const cardArtTwoTextBottomPrice = "$100 Per/Hr.";

const cardArtThreeTextTop = "Digital Workspace";
const cardArtThreeTextBottomCRA = "Collaboration Readiness Assessment Service";
const cardArtThreeTextBottomCS = "Microsoft Window’s Autopilot Service";
const cardArtThreeTextBottomDAS = "Device As A Service";
const cardArtThreeTextBottomDMS = "Document Management Solution";
const cardArtThreeTextBottomPrice = "$100 Per/Hr.";

const cardArtFourTextTop = "IT Intelligence";
const cardArtFourTextBottomCIS = "Cloud Intelligence Service";
const cardArtFourTextBottomCS = "Connectivity Services";
const cardArtFourTextBottomMVS = "Multi-Vendor Support Service";
const cardArtFourTextBottomCSFS = "Contact your Softcat AM for our full range of services";
const cardArtFourTextBottomPrice = "$100 Per/Hr.";


const CardArtOne = () => {
  return (
    <div class="plain-card-3">
      <div class="outer-clip-details">{cardArtOneTextTop}</div>
      <div class="clip-card-container-3">
        <div class="card-details-3">
          <ul>{cardArtOneTextBottomFirewall}</ul>
          <ul>{cardArtOneTextBottomIncident}</ul>
          <ul>{cardArtOneTextBottomMDR}</ul>
          <ul>{cardArtOneTextBottomCPS}</ul>
          <ul>{cardArtOneTextBottomPrice}</ul>
        </div>
      </div>
    </div>
  );
};

const CardArtTwo = () => {
  return (
    <div class="plain-card-3">
      <div class="outer-clip-details">{cardArtTwoTextTop}</div>
      <div class="clip-card-container-3">
        <div class="card-details-3">
           <ul>{cardArtTwoTextBottomADS}</ul>
           <ul>{cardArtTwoTextBottomAWS}</ul>
           <ul>{cardArtTwoTextBottomCDS}</ul>
           <ul>{cardArtTwoTextBottomADA}</ul>
           <ul>{cardArtTwoTextBottomPrice}</ul>
        </div>
      </div>
    </div>
  );
};

const CardArtThree = () => {
  return (
    <div class="plain-card-3">
      <div class="outer-clip-details">{cardArtThreeTextTop}</div>
      <div class="clip-card-container-3">
        <div class="card-details-3">
           <ul>{cardArtThreeTextBottomCRA}</ul>
           <ul>{cardArtThreeTextBottomCS}</ul>
           <ul>{cardArtThreeTextBottomDAS}</ul>
           <ul>{cardArtThreeTextBottomDMS}</ul>
           <ul>{cardArtThreeTextBottomPrice}</ul>
        </div>
      </div>
    </div>
  );
};


const CardArtFour = () => {
  return (
    <div class="plain-card-3">
      <div class="outer-clip-details">{cardArtFourTextTop}</div>
      <div class="clip-card-container-3">
        <div class="card-details-3">
           <ul>{cardArtFourTextBottomCIS}</ul>
           <ul>{cardArtFourTextBottomCS}</ul>
           <ul>{cardArtFourTextBottomMVS}</ul>
           <ul>{cardArtFourTextBottomCSFS}</ul>
           <ul>{cardArtFourTextBottomPrice}</ul>
        </div>
      </div>
    </div>
  );
};

class PlansAndPricing extends React.Component {
  render() {
    return (
      <section className="centerCards">
        <CardArtOne/>
        <CardArtTwo/>
        <CardArtThree/>
        <CardArtFour/>      
      </section>
    );
  }
}

export default PlansAndPricing;
