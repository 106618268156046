import './ClientList';
import React, { useState } from 'react';

function ClientListContent (props) {

    const paragraph = useState(props.paragraph);

    // const clickHandler = () => {
    //     setParagraph('Updated!!');
    //     console.log(paragraph);
    // };


    return (
        <div className="client-box-one-container">
            <div className="client-list-div1">
                <img
                    className="client-box-one-image"
                    src={props.image}>
                </img>
                <h1 className="client-box-one-h">{props.header}</h1>
                <p className="client-box-one-p">{paragraph}</p>
                {/* <button onClick={clickHandler}>change paragraph</button> */}
            </div>
        </div>
    )
}

export default ClientListContent

