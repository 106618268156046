import React, { useState } from "react";
import PageContent from "../pagecontent/content.json";
import Client from "../components/Clients/Clients"
import "../css/home.css";
import ClientList from "../components/Clients/ClientList";


const HeroContentParagraph = () => {
  return (
    <div>
      {PageContent.map((content,index) =>{
         return (<span key={index} id="sometext"> {content.paragraph} </span>
      );
      })}
    </div>
  );
};


const SecondParalax = () => {
  return (
    <div className="jumbotron paral paralsec1">
      <h1 className="display-3">Here is a heading 2</h1>      
    </div>
  );
};

const HeroParalax =(params)=> {
  return (
    <section>
      <div className="jumbotron paral paralsec">
        <div className="blockstay">
          <div id="alignit">
            <h1 className="display-3" > 
              Welcome<br/>To<br/>Bitslogical
            </h1>
            <div id="companyText">
              <span >We are an IT Solution Services <br/> &amp; Consulting Firm</span>
            </div>
            <div id="companyValueText">
            <span > At Bitslogical, we believe that technology should support and enhance your organization’s success, not constrain it. With our wide range of best-in-class services, we provide customized solutions that fit your unique IT needs. We’re committed to excelling at our job so you can focus on doing yours.</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

const ContactUsForm=()=>{

  
  const [enteredName, setName] = useState('');
  const [enteredNameError, setNameError] = useState(false);
  const [nameErrorMessage, setNameErrorMessage] = useState("");

  const [enteredEmail, setEmail] = useState('');
  const [enteredEmailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");

  const [enteredPhoneNumber, setPhoneNumber] = useState('');
  const [enteredPhoneNumberError, setPhoneNumberError] = useState(false);
  const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState("");

  const [enteredAddress, setAddress] = useState('');
  const [enteredAddressError, setAddressError] = useState(false);
  const [addressErrorMessage, setAddressErrorMessage] = useState("");

  const [enteredSubject, setSubject] = useState('');
  const [enteredSubjectError, setSubjectError] = useState(false);
  const [subjectErrorMessage, setSubjectErrorMessage] = useState("");

  const [enteredMessageBox, setMessageBox] = useState('');
  const [enteredMessageBoxError, setMessageBoxError] = useState(false);
  const [messageBoxErrorMessage, setMessageBoxErrorMessage] = useState("");


  const nameChangeHandler = (name)=>{
    if(!name.target.value){
      setNameError(true);
      setNameErrorMessage("Please Enter Your Name");
    } else if(name.length < 45 || (!/^[a-zA-Z\s]+$/.test(name.target.value))) {
      setNameError(true);
      setNameErrorMessage("Your name has an invalid character");  
    }else {
      setNameError(false);
      setName(name.target.value);
    } 
    
  };

  const emailChangeHandler = (email)=>{
    if(!email.target.value){
      setEmailError(true);
      setEmailErrorMessage("Please Enter Your Email");
    } else if(email.length < 253 || (!/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email.target.value))) {
      setEmailError(true);
      setEmailErrorMessage("Your email address has an invalid character.");  
    }else {
      setEmailError(false);
      setEmail(email.target.value);
    } 
    
  }

  
  const phoneNumberChangeHandler = (phone)=>{
    if (!phone.target.value) {
      setPhoneNumberError(true);
      setPhoneNumberErrorMessage("Please Enter Your Phone Number.");
    } else if(phone.length < 10 || (!/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(phone.target.value))) {
      setPhoneNumberError(true);
      setPhoneNumberErrorMessage("Your phone number is in the incorrect format.");
    } else {
      setPhoneNumberError(false);
      setPhoneNumber(phone.target.value);
    }
    
  }

  const addressChangeHandler = (address)=>{
    if (!address.target.value) {
      setAddressError(true);
      setAddressErrorMessage("Please enter your address.");
    } else if(address.length < 253 || (!/^[a-zA-Z0-9,.\s]+$/.test(address.target.value))) {
      setAddressError(true);
      setAddressErrorMessage("Your address has an invalid character.");      
    } else {
      setAddressError(false);
      setAddress(address.target.value);
    }
      
  }

  const subjectChangeHandler = (subject)=>{
    if (!subject.target.value) {
      setSubjectError(true);
      setSubjectErrorMessage("Please enter in the subject line.");
    } else if(subject.length < 35 || (!/^[a-zA-Z\s]+$/.test(subject.target.value))) {
      setSubjectError(true);
      setSubjectErrorMessage("Your subject line has an invalid character.");
    } else {
      setSubjectError(false);
      setSubject(subject.target.value);
    }
  }

  const messageChangeHandler = (message)=>{
    if (!message.target.value) {
      setMessageBoxError(true);
      setMessageBoxErrorMessage("Please do not leave the message box blank.");
    } else {
      setMessageBoxError(false);
      setMessageBox(message.target.value);
    }

  }


  const submitHandler = ()=>{
     if(enteredName === "") {
       setNameError(true);
       setNameErrorMessage("Please enter in your name.");  
     }

     if(enteredEmail === ""){
       setEmailError(true);
       setEmailErrorMessage("Please enter in your email address.");
     }

     if (enteredPhoneNumber === "") {
       setPhoneNumberError(true);
       setPhoneNumberErrorMessage("Please enter in your phone number.");
     }

     if (enteredAddress === "") {
       setAddressError(true);
       setAddressErrorMessage("Please enter in your address.");
     }

     if(enteredSubject === ""){
       setSubjectError(true);
       setSubjectErrorMessage("Please enter in the subject.");
     }

     if(enteredMessageBox === ""){
       setMessageBoxError(true);
       setMessageBoxErrorMessage("Please enter a message.");
     }
    
  }
  
  
  return(   
    <section className="contactFormText mb-4" id="contactFormBackground">
        <h2 className="headingText h2-responsive font-weight-bold text-center my-4">Contact us</h2>
        <p className="text-center w-responsive mx-auto mb-5">Do you have any questions? Please do not hesitate to contact us directly. Our team will come back to you within
            a matter of hours to help you.</p>
            <div className="col-md-12" >
                <ul className="list-unstyled mb-0" id="contactUsCompanyInfo">
                    <li>
                        <p>Minnesota, MN 55340, USA</p>
                    </li>

                    <li>
                        <p>651-279-8985</p>
                    </li>

                    <li>
                        <p>support@BitsLogical.com</p>
                    </li>
                </ul>
            </div>  

            

      <div className="row">
        <div className="col-md-12 mb-md-0 mb-5">
          <form id="contact-form" name="contact-form" action="mail.php" method="POST">
            <div className="row">

              <div className="col-md-6">
                <div className="md-form mb-0">
                  <label
                    htmlFor="name"
                    className={enteredNameError ? "errorMessages" : ""}>Name</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    required className={enteredNameError ? "form-control errorBox" : "form-control inputBox"}
                    placeholder="Name"
                    maxLength="35"
                    onChange={nameChangeHandler} ></input>
                  {enteredNameError && <p className="errorMessages">{nameErrorMessage}</p>}
                </div>
              </div>

              <div className="col-md-6">
                <div className="md-form mb-0">
                  <label htmlFor="email" className={enteredEmailError ? "errorMessages" : ""}>Email</label>
                  <input 
                  type="text" 
                  id="email" 
                  name="email" 
                  required className={enteredEmailError ? "form-control errorBox" : "form-control inputBox"} 
                  placeholder="Email" 
                  maxLength="35" 
                  onChange={emailChangeHandler}></input>
                  {enteredEmailError && <p className="errorMessages">{emailErrorMessage}</p>}
                </div>
              </div>

              <div className="col-md-6">
                <div className="md-form mb-0">
                  <label htmlFor="phoneNumber" className={enteredPhoneNumberError ? "errorMessages" : ""}>Phone Number</label>
                  <input type="text" id="phoneNumber" name="phoneNumber" className={enteredPhoneNumberError ? "form-control errorBox" : "form-control inputBox"} placeholder="Ex: 123-456-7890" maxLength="12" onChange={phoneNumberChangeHandler}></input>
                  {enteredPhoneNumberError && <p className="errorMessages">{phoneNumberErrorMessage}</p>}
                </div>
              </div>

              <div className="col-md-6">
                <div className="md-form mb-0">
                  <label htmlFor="email" className={enteredAddressError ? "errorMessages" : ""}>Address</label>
                  <input type="text" id="address" name="address" className={enteredAddressError ? "form-control errorBox" : "form-control inputBox"} placeholder="Address" maxLength="253" onChange={addressChangeHandler}></input>
                  {enteredAddressError && <p className="errorMessages">{addressErrorMessage}</p>}
                </div>
              </div>
            </div>


            <div className="row">
              <div className="col-md-12">
                <div className="md-form mb-0">
                  <label htmlFor="subject" className={enteredSubjectError ? "errorMessages" : ""}>Subject</label>
                  <input type="text" id="subject" name="subject" className={enteredSubjectError ? "form-control errorBox" : "form-control inputBox"} placeholder="Subject" maxLength="35" onChange={subjectChangeHandler}></input>
                  {enteredSubjectError && <p className="errorMessages">{subjectErrorMessage}</p>}
                </div>
              </div>
            </div>



            <div className="row">
              <div className="col-md-12">
                <div className="md-form">
                  <label htmlFor="message" className={enteredMessageBoxError ? "errorMessages" : ""}>Your Message</label>
                  <textarea type="text" id="message" name="message" rows="2" className={enteredEmailError ? "form-control errorBox" : "form-control inputBox"} placeholder="Type your message here... " onChange={messageChangeHandler}></textarea>
                  {enteredMessageBoxError && <p className="errorMessages">{messageBoxErrorMessage}</p>}
                </div>
              </div>
            </div>
          </form>

          <div className="text-center text-md-center">
            <button className="btn btn-primary" onClick={submitHandler} id="submitBtn">Send</button>
          </div>
          <div className="status"></div>
        </div>
      </div>
    </section>
  );
}


class Home extends React.Component {
  render() {
    return <section>
        <HeroParalax/>
        <SecondParalax/>
        <Client/>
        <ClientList/>
        <ContactUsForm/>
    </section>;
  }
}

export default Home;
