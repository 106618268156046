import React from "react";
import "../css/services.css";

const ServicesHeading = () => {
  return (
    <section className="textAlignment">
      <h1 className="servicesHeadingText">Our Comprehensive Services</h1>
      <p className="paragraphtext">
        Bitslogical provides a variety of innovative and comprehensive IT
        solutions. We deliver quality services in the most efficient way, and
        our <br /> experts will work collaboratively with you to customize our
        offerings to your particular needs. Book a meeting with one of our
        consultants to <br /> hear more about how we can assist your operation.
      </p>
    </section>
  );
};

const Images = () => {
  return (
    <section>
        <div className="firstImagePosition">
          <div className="textBox">
            <h5 className="headingText">Cybersecurity &amp; Privacy</h5>
            <span className="textAlign">Our IT solutions carve the path for organizations to modernize infrastructure and optimize workflow like never before. 
              This service provides a wide variety of technological capabilities to boost efficiencies,
              enhance security and allow infinite scalability for future growth.
              With this resource and our expertise, 
              we’ll help maximize your organization’s productivity.</span>
          </div>
        </div>

        <div className="secondImagePosition">
          <div className="textBox">
            <h5 className="headingText">Infrastructure Management</h5>
            <span className="textAlign">In today’s hyperconnected world, organizations are challenged in more ways than ever to stay ahead of the curve. 
            This comprehensive service is designed to fit your unique needs without disrupting productivity or workflow. 
            With our end-to-end solutions, Bitslogical is sure to help your organization succeed.</span>
          </div>
        </div>

        <div className="thirdImagePosition">
          <div className="textBox">
            <h5 className="headingText">Network Services</h5>
            <span className="textAlign">Enabling your workforce with top-notch technologies isn’t just important, but imperative for business success. 
            This customizable solution allows your team to work seamlessly and collaboratively in a protected space. 
            No matter what IT services you need, Bitslogical will be there to support you every step of the way.</span>
          </div>
        </div>  
    </section>
  );
};

class Services extends React.Component {
  render() {
    return (
      <section>
        <ServicesHeading />
        <Images />
      </section>
    );
  }
}

export default Services;
