import React from 'react';
import ClientList from './ClientList';
import ClientVideo from './ClientVideo';
// import './client.css';


function Clients() {

    return (
        <>
            <ClientVideo />
            {/* <ClientList/> */}
        </>
    )
}


export default Clients
