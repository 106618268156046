import { propTypes } from 'react-bootstrap/esm/Image';
import './client.css';
import './ClientListContent';
import ClientListContent from './ClientListContent';


function ClientList() {

    const clientListStuff = [
        {
            image: 'https://static.wixstatic.com/media/175d9a096f174d819f80b533069116cb.png/v1/fill/w_440,h_440,al_c,q_85,usm_0.66_1.00_0.01/Client%207.webp',
            header: 'Polar',
            paragraph: '"By really listening to our technology needs, Bitslogical provided solutions that fit our unique business model. Their ongoing support and services have been instrumental to our company’s growth."'
        },
        {
            image: 'https://static.wixstatic.com/media/64453492563a4dd4a6fff4dd901e6040.png/v1/fill/w_330,h_330,al_c,q_85,usm_0.66_1.00_0.01/Client%208.webp',
            header: 'Itaka',
            paragraph: '"Our company has confidently chosen Bitslogical for our IT needs over many years. They always make sure we’re ahead of the technology curve, and are also very enjoyable to work with"'
        },
        {
            image: 'https://static.wixstatic.com/media/0fa92a967c76420a83938ecadb5ba4f2.png/v1/fill/w_330,h_330,al_c,q_85,usm_0.66_1.00_0.01/Client%202.webp',
            header: 'Hexa',
            paragraph: '"Bitslogical transformed our company by taking us mobile and implementing cloud solutions, which gave our employees the flexibility they needed to work most efficiently."'
        },
        {
            image: 'https://static.wixstatic.com/media/8083f7b02b824feaac22f26d88b3f5a8.png/v1/fill/w_330,h_330,al_c,q_85,usm_0.66_1.00_0.01/Client%203.webp',
            header: 'Targo',
            paragraph: '“By really listening to our technology needs, Bitslogical provided solutions that fit our unique business model. Their ongoing support and services have been instrumental to our company’s growth.”'
        },
    ];

    return (
        <section className="client-list-section">
            <ClientListContent 
                image={clientListStuff[0].image}
                header={clientListStuff[0].header}
                paragraph={clientListStuff[0].paragraph}>
            </ClientListContent>

            <ClientListContent 
                image={clientListStuff[1].image}
                header={clientListStuff[1].header}
                paragraph={clientListStuff[1].paragraph}>
            </ClientListContent>

            <ClientListContent 
                image={clientListStuff[2].image}
                header={clientListStuff[2].header}
                paragraph={clientListStuff[2].paragraph}>
            </ClientListContent>

            <ClientListContent 
                image={clientListStuff[3].image}
                header={clientListStuff[3].header}
                paragraph={clientListStuff[3].paragraph}>
            </ClientListContent>
        </section>
   
    )
}

export default ClientList