import React, { useState } from 'react';
import "./contactPageForm.css";

const ContactUsForm=()=>{

  
    const [enteredName, setName] = useState('');
    const [enteredNameError, setNameError] = useState(false);
    const [nameErrorMessage, setNameErrorMessage] = useState("");
  
    const [enteredEmail, setEmail] = useState('');
    const [enteredEmailError, setEmailError] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState("");
  
    const [enteredPhoneNumber, setPhoneNumber] = useState('');
    const [enteredPhoneNumberError, setPhoneNumberError] = useState(false);
    const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState("");
  
    // const [enteredAddress, setAddress] = useState('');
    // const [enteredAddressError, setAddressError] = useState(false);
    // const [addressErrorMessage, setAddressErrorMessage] = useState("");
  
    const [enteredSubject, setSubject] = useState('');
    const [enteredSubjectError, setSubjectError] = useState(false);
    const [subjectErrorMessage, setSubjectErrorMessage] = useState("");
  
    const [enteredMessageBox, setMessageBox] = useState('');
    const [enteredMessageBoxError, setMessageBoxError] = useState(false);
    const [messageBoxErrorMessage, setMessageBoxErrorMessage] = useState("");
  
  
    const nameChangeHandler = (name)=>{
      if(!name.target.value){
        setNameError(true);
        setNameErrorMessage("Please Enter Your Name");
      } else if(name.length < 45 || (!/^[a-zA-Z\s]+$/.test(name.target.value))) {
        setNameError(true);
        setNameErrorMessage("Your name has an invalid character");  
      }else {
        setNameError(false);
        setName(name.target.value);
      } 
      
    };
  
    const emailChangeHandler = (email)=>{
      if(!email.target.value){
        setEmailError(true);
        setEmailErrorMessage("Please Enter Your Email");
      } else if(email.length < 253 || (!/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email.target.value))) {
        setEmailError(true);
        setEmailErrorMessage("Your email address has an invalid character.");  
      }else {
        setEmailError(false);
        setEmail(email.target.value);
      } 
      
    }
  
    
    const phoneNumberChangeHandler = (phone)=>{
      if (!phone.target.value) {
        setPhoneNumberError(true);
        setPhoneNumberErrorMessage("Please Enter Your Phone Number.");
      } else if(phone.length < 10 || (!/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(phone.target.value))) {
        setPhoneNumberError(true);
        setPhoneNumberErrorMessage("Your phone number is in the incorrect format.");
      } else {
        setPhoneNumberError(false);
        setPhoneNumber(phone.target.value);
      }
      
    }
  
    // const addressChangeHandler = (address)=>{
    //   if (!address.target.value) {
    //     setAddressError(true);
    //     setAddressErrorMessage("Please enter your address.");
    //   } else if(address.length < 253 || (!/^[a-zA-Z0-9,.\s]+$/.test(address.target.value))) {
    //     setAddressError(true);
    //     setAddressErrorMessage("Your address has an invalid character.");      
    //   } else {
    //     setAddressError(false);
    //     setAddress(address.target.value);
    //   }
        
    // }
  
    const subjectChangeHandler = (subject)=>{
      if (!subject.target.value) {
        setSubjectError(true);
        setSubjectErrorMessage("Please enter in the subject line.");
      } else if(subject.length < 35 || (!/^[a-zA-Z\s]+$/.test(subject.target.value))) {
        setSubjectError(true);
        setSubjectErrorMessage("Your subject line has an invalid character.");
      } else {
        setSubjectError(false);
        setSubject(subject.target.value);
      }
    }
  
    const messageChangeHandler = (message)=>{
      if (!message.target.value) {
        setMessageBoxError(true);
        setMessageBoxErrorMessage("Please do not leave the message box blank.");
      } else {
        setMessageBoxError(false);
        setMessageBox(message.target.value);
      }
  
    }
  
  
    const submitHandler = ()=>{
       if(enteredName === "") {
         setNameError(true);
         setNameErrorMessage("Please enter in your name.");  
       }
  
       if(enteredEmail === ""){
         setEmailError(true);
         setEmailErrorMessage("Please enter in your email address.");
       }
  
       if (enteredPhoneNumber === "") {
         setPhoneNumberError(true);
         setPhoneNumberErrorMessage("Please enter in your phone number.");
       }
  
      //  if (enteredAddress === "") {
      //    setAddressError(true);
      //    setAddressErrorMessage("Please enter in your address.");
      //  }
  
       if(enteredSubject === ""){
         setSubjectError(true);
         setSubjectErrorMessage("Please enter in the subject.");
       }
  
       if(enteredMessageBox === ""){
         setMessageBoxError(true);
         setMessageBoxErrorMessage("Please enter a message.");
       }
      
    }

    return (
        <div className="contactusFormContainer">

            <div className="left-side-div-container">
                <h3 className="heading-style">Let's talk about everything!</h3>
                <p className="paragraph-style">"Want to work on a project? Have questions? Want to complain about a significant other? Just want to say hi? Shoot us a message!"</p>
                <img
                    src="https://preview.colorlib.com/theme/bootstrap/contact-form-16/images/undraw-contact.svg"
                    alt="Image"
                    class="img-fluid">
                </img>
            </div>
            <div className="right-side-div-container">
                <form>
                    <div>
                        {enteredNameError && <p className="error-message-input-box">{nameErrorMessage}</p>}
                        <input
                            type="text"
                            id="name"
                            name="name"
                            required className={enteredNameError ? "form-control errorBox" : "contactUsFormName"}
                            placeholder="Name"
                            maxLength="35"
                            onChange={nameChangeHandler}
                        />
                    </div>

                    <div>
                        {enteredEmailError && <p className="error-message-input-box">{emailErrorMessage}</p>}
                        <input
                            type="text"
                            id="email"
                            name="email"
                            required className={enteredEmailError ? "form-control errorBox" : "contactUsForm1"}
                            placeholder="Email"
                            maxLength="35"
                            onChange={emailChangeHandler}
                        />
                    </div>

                    <div>
                        {enteredPhoneNumberError && <p className="error-message-input-box">{phoneNumberErrorMessage}</p>}
                        <input
                            type="text" 
                            id="phoneNumber" 
                            name="phoneNumber" 
                            className={enteredPhoneNumberError ? "form-control errorBox" : "contactUsForm1"} 
                            placeholder="Phone Number" 
                            maxLength="12" 
                            onChange={phoneNumberChangeHandler}
                        />
                    </div>

                    <div>
                        {enteredSubjectError && <p className="error-message-input-box">{subjectErrorMessage}</p>}
                        <input
                            type="text" 
                            id="subject" 
                            name="subject" 
                            className={enteredSubjectError ? "form-control errorBox" : "contactUsForm1"} 
                            placeholder="Subject" 
                            maxLength="35" 
                            onChange={subjectChangeHandler}
                        />
                    </div>

                    <div>
                    {enteredMessageBoxError && <p className="error-message-input-box">{messageBoxErrorMessage}</p>}
                        <textarea
                            type="text" 
                            id="message" 
                            name="message" 
                            rows="7" 
                            cols="30"
                            className={enteredMessageBoxError ? "form-control errorBox" : "contactUsForm1"} 
                            placeholder="Type your message here... " 
                            onChange={messageChangeHandler}
                        />
                    </div>

                    <div>
                        <div>
                            <input 
                                type="submit"
                                value="Send Message"
                                className="send-message-btn"
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )

    }
    
export default ContactUsForm

    
