import { BrowserRouter  as Router, Route, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/menu.css";
import "../css/navbar.css";



function NavigationBar(props) {
  return (
   
      <nav className="navbar navbar-expand-sm navbar-light bg-light">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-center"
          id="navbarNavDropdown"
        >
          <ul className="navbar-nav">
            <li className="nav-item active">
              <Link to="/Home" className="nav-link">
                Home
              </Link>
            </li>

            <li className="nav-item active">
              <Link to="/About" className="nav-link">
                About
              </Link>
            </li>
            
            <li className="nav-item dropdown active">
              <Link to="/Services" className="nav-link">
                Services
              </Link>
            </li>

            <li className="nav-item active">
              <Link to="/PlansAndPricing" className="nav-link">
                Plans &amp; Pricing
              </Link>
            </li>

            <li className="nav-item active">
              <Link to="/Contact" className="nav-link">
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </nav>

  );
}

export default NavigationBar;